export const sortListTxKeseluruhan = [
  {id: 1, name: 'Nama Sekolah (Ascending)', value: 'school_name ASC'},
  {id: 2, name: 'Nama Sekolah (Descending)', value: 'school_name DESC'},
  {id: 3, name: 'Transaksi ID (Ascending)', value: 'tx_id ASC'},
  {id: 4, name: 'Transaksi ID (Descending)', value: 'tx_id DESC'},
  {id: 5, name: 'Status (Ascending)', value: 'tx_status_label ASC'},
  {id: 6, name: 'Status (Descending)', value: 'tx_status_label DESC'},
  {id: 7, name: 'Jumlah (Ascending)', value: 'tx_value_total ASC'},
  {id: 8, name: 'Jumlah (Descending)', value: 'tx_value_total DESC'}
];

export const sortListTxSumberDana = [
  {id: 1, name: 'Nama (Ascending)', value: 'name ASC'},
  {id: 2, name: 'Nama (Descending)', value: 'name DESC'},
  {id: 3, name: 'Jumlah Sekolah (Ascending)', value: 'school_count ASC'},
  {id: 4, name: 'Jumlah Sekolah (Descending)', value: 'school_count DESC'},
  {id: 5, name: 'Pesanan Dikirim (Ascending)', value: 'pesanan_dikirim_penjual ASC'},
  {id: 6, name: 'Pesanan Dikirim (Descending)', value: 'pesanan_dikirim_penjual DESC'},
  {id: 7, name: 'Pesanan Diproses (Ascending)', value: 'pesanan_diproses_penjual ASC'},
  {id: 8, name: 'Pesanan Diproses (Descending)', value: 'pesanan_diproses_penjual DESC'},
  {id: 9, name: 'Pesanan Diterima (Ascending)', value: 'pesanan_diterima_sekolah ASC'},
  {id: 10, name: 'Pesanan Diterima (Descending)', value: 'pesanan_diterima_sekolah DESC'},
  {id: 11, name: 'Pesanan Dikomplain (Ascending)', value: 'pesanan_dikomplain_sekolah ASC'},
  {id: 12, name: 'Pesanan Dikomplain (Descending)', value: 'pesanan_dikomplain_sekolah DESC'},
  {id: 13, name: 'Bast Dibuat Sekolah (Ascending)', value: 'bast_dibuat_sekolah ASC'},
  {id: 14, name: 'Bast Dibuat Sekolah (Descending)', value: 'bast_dibuat_sekolah DESC'}
];

export const sortListSchoolSumberDana = [
  {id: 1, name: 'Nama (Ascending)', value: 'nm_sekolah ASC'},
  {id: 2, name: 'Nama (Descending)', value: 'nm_sekolah DESC'},
  {id: 3, name: 'NPSN (Ascending)', value: 'npsn ASC'},
  {id: 4, name: 'NPSN (Descending)', value: 'npsn DESC'},
  {id: 5, name: 'Pesanan Dikirim (Ascending)', value: 'pesanan_dikirim_penjual ASC'},
  {id: 6, name: 'Pesanan Dikirim (Descending)', value: 'pesanan_dikirim_penjual DESC'},
  {id: 7, name: 'Pesanan Diproses (Ascending)', value: 'pesanan_diproses_penjual ASC'},
  {id: 8, name: 'Pesanan Diproses (Descending)', value: 'pesanan_diproses_penjual DESC'},
  {id: 9, name: 'Pesanan Diterima (Ascending)', value: 'pesanan_diterima_sekolah ASC'},
  {id: 10, name: 'Pesanan Diterima (Descending)', value: 'pesanan_diterima_sekolah DESC'},
  {id: 11, name: 'Pesanan Dikomplain (Ascending)', value: 'pesanan_dikomplain_sekolah ASC'},
  {id: 12, name: 'Pesanan Dikomplain (Descending)', value: 'pesanan_dikomplain_sekolah DESC'},
  {id: 13, name: 'Bast Dibuat Sekolah (Ascending)', value: 'bast_dibuat_sekolah ASC'},
  {id: 14, name: 'Bast Dibuat Sekolah (Descending)', value: 'bast_dibuat_sekolah DESC'},
];

export const sortListProfileSchool = [
  {id: 1, name: 'Transaksi ID (Ascending)', value: 'tx_id ASC'},
  {id: 2, name: 'Transaksi ID (Descending)', value: 'tx_id DESC'},
  {id: 3, name: 'Status Transaksi (Ascending)', value: 'tx_status_label ASC'},
  {id: 4, name: 'Status Transaksi (Descending)', value: 'tx_status_label DESC'},
  {id: 5, name: 'Total Nilai Transaksi (Ascending)', value: 'tx_value_total ASC'},
  {id: 6, name: 'Total Nilai Transaksi (Descending)', value: 'tx_value_total DESC'},
  {id: 7, name: 'Created at (Ascending)', value: 'created_at ASC'},
  {id: 8, name: 'Created at (Descending)', value: 'created_at DESC'}
];

export const ROLE_INSTANSI = {
  SATUAN_PENDIDIKAN: 2,
  DINAS_KABKOTA: 3,
  DINAS_PROVINSI: 4,
  PENGAWAS_PUSAT: 5,
  PENGAWAS_PROVINSI: 6,
  PENGAWAS_KABKOTA: 7,
  MITRA_SIPLAH: 8,
  SUPER_ADMIN: 9,
  PENGAWAS_KEMENTRIAN: 10,
}

export const TABLE_DOMAIN = {
  TRANSACTIONS_INVOICES: 'transactions' || 'invoices',
  CHATS: 'chats',
  MERCHANTS: 'merchants',
  ITEMS: 'items',
  SCHOOLS: 'schools',
  NEGOTIATIONS: 'negotiations',
  COMPARISONS: 'comparisons',
  TRANSACTION_INVOICE_ITEMS: 'transaction_items' || 'transaction_ban_items' || 'invoice_items',
  COMPARISON_ITEMS: 'comparison_items',
  MERCHANT_DOCUMENTS: 'merchant_documents',
  ITEM_PRICES: 'item_prices',
  MERCHANT_SHIPPING_OPTIONS: 'merchant_shipping_options',
  ITEM_SHIPPING_OPTIONS: 'item_shipping_options',
  ACTIVITIES: 'activities'
}